// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SignatureNoBankId_reset-list_E1vCx{margin:0;padding:0;list-style:none;display:block}.SignatureNoBankId_uppercase-sm_inncr{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.SignatureNoBankId_formContainer_1zZBU{max-width:263px;margin:0 auto}.SignatureNoBankId_label_bY6Jn{color:#9ca6b1;margin-top:24px}.SignatureNoBankId_highlight_1la44{font-weight:700;font-size:20px}", ""]);
// Exports
exports.locals = {
	"reset-list": "SignatureNoBankId_reset-list_E1vCx",
	"uppercase-sm": "SignatureNoBankId_uppercase-sm_inncr",
	"formContainer": "SignatureNoBankId_formContainer_1zZBU",
	"label": "SignatureNoBankId_label_bY6Jn",
	"highlight": "SignatureNoBankId_highlight_1la44"
};
module.exports = exports;
