// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SignatureSimplySign_reset-list_j9YLT{margin:0;padding:0;list-style:none;display:block}.SignatureSimplySign_uppercase-sm_3LJ1Y{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.SignatureSimplySign_formContainer_1SfXd{max-width:263px;margin:0 auto}.SignatureSimplySign_label_28Q-t{color:#9ca6b1;margin-top:24px}.SignatureSimplySign_highlight_GMi-s{font-weight:700;font-size:20px}", ""]);
// Exports
exports.locals = {
	"reset-list": "SignatureSimplySign_reset-list_j9YLT",
	"uppercase-sm": "SignatureSimplySign_uppercase-sm_3LJ1Y",
	"formContainer": "SignatureSimplySign_formContainer_1SfXd",
	"label": "SignatureSimplySign_label_28Q-t",
	"highlight": "SignatureSimplySign_highlight_GMi-s"
};
module.exports = exports;
