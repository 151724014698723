// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EdoAppEidButton_reset-list_1UiSC{margin:0;padding:0;list-style:none;display:block}.EdoAppEidButton_uppercase-sm_3AEfg{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.EdoAppEidButton_icon_2hokT{display:block;width:118px;height:33px;margin-right:8px}", ""]);
// Exports
exports.locals = {
	"reset-list": "EdoAppEidButton_reset-list_1UiSC",
	"uppercase-sm": "EdoAppEidButton_uppercase-sm_3AEfg",
	"icon": "EdoAppEidButton_icon_2hokT"
};
module.exports = exports;
