// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".YesComButton_reset-list_3k7Vg{margin:0;padding:0;list-style:none;display:block}.YesComButton_uppercase-sm_336Oj{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.YesComButton_text_seN_V{font-size:18px}", ""]);
// Exports
exports.locals = {
	"reset-list": "YesComButton_reset-list_3k7Vg",
	"uppercase-sm": "YesComButton_uppercase-sm_336Oj",
	"text": "YesComButton_text_seN_V"
};
module.exports = exports;
