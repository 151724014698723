// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SignatureFtnAdvancedFull_reset-list_2lkf2{margin:0;padding:0;list-style:none;display:block}.SignatureFtnAdvancedFull_uppercase-sm_nBHGo{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.SignatureFtnAdvancedFull_formContainer_11JCe{max-width:263px;margin:0 auto}.SignatureFtnAdvancedFull_label_3U05W{color:#9ca6b1;margin-top:24px}.SignatureFtnAdvancedFull_highlight_2SUQp{font-weight:700;font-size:20px}", ""]);
// Exports
exports.locals = {
	"reset-list": "SignatureFtnAdvancedFull_reset-list_2lkf2",
	"uppercase-sm": "SignatureFtnAdvancedFull_uppercase-sm_nBHGo",
	"formContainer": "SignatureFtnAdvancedFull_formContainer_11JCe",
	"label": "SignatureFtnAdvancedFull_label_3U05W",
	"highlight": "SignatureFtnAdvancedFull_highlight_2SUQp"
};
module.exports = exports;
