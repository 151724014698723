// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MethodSelection_reset-list_1hTEY{margin:0;padding:0;list-style:none;display:block}.MethodSelection_uppercase-sm_1wCO9{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.MethodSelection_methodSelection_27yYs{max-width:510px;margin:0 auto}.MethodSelection_methodSelectionThin_ClUxk{max-width:348px;margin:0 auto}.MethodSelection_row_2NmRu{display:flex;flex-wrap:wrap;margin-left:-8px;margin-right:-8px}.MethodSelection_unit_3UKwV{padding:8px 8px;flex-grow:1;flex-basis:0}.MethodSelection_buttonInstructions_35TRT{text-align:center;padding-bottom:4px;color:#3a3a3a;font-size:14px}", ""]);
// Exports
exports.locals = {
	"reset-list": "MethodSelection_reset-list_1hTEY",
	"uppercase-sm": "MethodSelection_uppercase-sm_1wCO9",
	"methodSelection": "MethodSelection_methodSelection_27yYs",
	"methodSelectionThin": "MethodSelection_methodSelectionThin_ClUxk",
	"row": "MethodSelection_row_2NmRu",
	"unit": "MethodSelection_unit_3UKwV",
	"buttonInstructions": "MethodSelection_buttonInstructions_35TRT"
};
module.exports = exports;
