<script>
import MethodButtonBase from './MethodButtonBase';
import EdoAppLogo from '../../assets/svg/edoapp-logo.svg';
export default {
  name: 'EdoAppEidButton',
  components: {
    MethodButtonBase,
    EdoAppLogo,
  },
}
</script>

<template>
  <MethodButtonBase>
    <template v-slot:slot1>
      <EdoAppLogo :class="$style.icon" />
    </template>
  </MethodButtonBase>
</template>

<style lang="scss" module>
.icon {
  display: block;
  width: 118px;
  height: 33px;
  margin-right: 8px;
}
</style>
