import MethodSelection from '../views/MethodSelection.vue';
import SmartIdAuth from '../views/SmartIdAuth';
import MobileIdAuth from '../views/MobileIdAuth';
import IdentificationFrejaId from './IdentificationFrejaId';
import IdentificationZealId from './IdentificationZealId';
import SignatureSmartId from './SignatureSmartId';
import SignatureMobileId from './SignatureMobileId';
import SignatureFtn from './SignatureFtn';
import SignatureFtnAdvancedFull from './SignatureFtnAdvancedFull';
import SignatureOtp from './SignatureOtp';
import SignatureEvrotrust from './SignatureEvrotrust';
import SignatureDTrustSignMe from './SignatureDTrustSignMe';
import SignatureChaveMovel from './SignatureChaveMovel';
import SignatureNoBankId from './SignatureNoBankId';
import SignatureSpidQes from './SignatureSpidQes';
import SignatureFrejaId from './SignatureFrejaId';
import SignatureAudkenni from './SignatureAudkenni';
import SignatureSimplySign from './SignatureSimplySign';

const views = Object.freeze({
  MethodSelection,
  SmartIdAuth,
  MobileIdAuth,
  IdentificationFrejaId,
  IdentificationZealId,
  SignatureSmartId,
  SignatureMobileId,
  SignatureFtn,
  SignatureOtp,
  SignatureEvrotrust,
  SignatureDTrustSignMe,
  SignatureChaveMovel,
  SignatureFtnAdvancedFull,
  SignatureNoBankId,
  SignatureSpidQes,
  SignatureFrejaId,
  SignatureAudkenni,
  SignatureSimplySign,
});

export default views;
