<script>
import MethodButtonBase from './MethodButtonBase';
import AudkenniIcon from '../../assets/svg/audkenni-logo.svg';
export default {
  name: 'AudkenniButton',
  components: {
    MethodButtonBase,
    AudkenniIcon
  },
}
</script>

<template>
  <MethodButtonBase>
    <template v-slot:slot1>
      <AudkenniIcon :class="$style.icon" />
    </template>
  </MethodButtonBase>
</template>

<style lang="scss" module>
.icon {
  display: block;
  width: 151px;
  height: auto;
}
</style>