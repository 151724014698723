<script>
import AppForm from '../components/AppForm';
import getFieldErrors from '../getFieldErrors';
import AppButton from '../components/AppButton';
import ChallengeLoader from '../components/ChallengeLoader';

export default {
  name: 'IdentificationFrejaId',
  components: {
    AppForm,
    AppButton,
    ChallengeLoader,
  },
  data() {
    return {
      fieldErrors: {},
      userInstructions: undefined,
      authProcess: {},
    }
  },
  computed: {
    inputValues() {
      return this.$root.$options.storeGetters.inputValues();
    },
    additionalMethodConfig() {
      return this.$root.$options.storeGetters.additionalMethodConfig();
    },
    countryCode() {
      return this.$root.$options.storeGetters.countryCode();
    },
    schema() {
      return [
        {
          type: 'text',
          name: 'idcode',
          label: this.$t('idcode'),
          placeholder: this.$t(this.countryCode.toLowerCase() + '-freja-id-code-placeholder'),
          validation: ['required'],
        },
        {
          type: 'submit',
          label: this.$t('log-in')
        }
      ]
    },
    formValue: {
      get() {
        return {
          idcode:  this.inputValues.idcode,
        }
      },
      set(newValues){
        this.updateInputValues(newValues);
      }
    },
  },
  beforeDestroy() {
    this.cancel();
  },
  methods: {
    updateInputValues(newValues) {
      this.$root.$options.storeActions.updateInputValues(newValues);
    },
    loadingStart() {
      this.$root.$options.storeActions.loadingStart();
    },
    loadingEnd() {
      this.$root.$options.storeActions.loadingEnd();
    },
    addFlashMessage(message) {
      this.$root.$options.storeActions.addFlashMessage(message);
    },
    clearFlashMessages() {
      this.$root.$options.storeActions.clearFlashMessages();
    },
    clearErrors() {
      this.fieldErrors = {};
    },
    cancel() {
      const {cancel} = this.authProcess;
      if (cancel) {
        cancel();
      }
    },
    authenticate() {
      this.loadingStart();
      this.clearFlashMessages();
      this.clearErrors();
      const additionalMethodConfig = this.additionalMethodConfig || {};
      this.authProcess = this.$root.$options.eidEasyClient.identification.frejaEid.start({
        ...this.formValue,
        started: (result) => {
          if (result.data) {
            this.userInstructions = this.$t('approve-id-request-on-freja-app');
            this.loadingEnd();
          }
        },
        fail: (error) => {
          if (!error.isCancel) {
            this.addFlashMessage(error);
          }
          this.fieldErrors = getFieldErrors(error);
          this.$root.$options.eidEasyOnFail(error);
        },
        success: (result) => {
          this.$root.$options.eidEasyOnSuccess(result);
        },
        finished: () => {
          this.userInstructions = undefined;
          this.loadingEnd();
        },
        ...additionalMethodConfig,
      });
    }
  },
}
</script>

<template>
  <div :class="$style.formContainer">
    <div
      v-if="userInstructions"
      :class="$style.userInstructions"
    >
      <ChallengeLoader :user-instructions="userInstructions" />
      <AppButton
        scheme="secondary"
        :on-click="() => cancel()"
      >
        {{ $t('cancel') }}
      </AppButton>
    </div>
    <div v-else>
      <AppForm
        id="frejaIdForm"
        v-model="formValue"
        :schema="schema"
        :on-submit="authenticate"
        :errors="fieldErrors"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.formContainer {
  max-width: $form-container-width;
  margin: 0 auto;
}
</style>
