<script>
import MethodButtonBase from './MethodButtonBase';
import VerifaiIcon from '../../assets/svg/verifai-logo.svg';
export default {
  name: 'VerifaiButton',
  components: {
    MethodButtonBase,
    VerifaiIcon,
  },
}
</script>

<template>
  <MethodButtonBase>
    <template v-slot:slot1>
      <VerifaiIcon :class="$style.icon" />
    </template>
  </MethodButtonBase>
</template>

<style lang="scss" module>
.icon {
  display: block;
  width: 71px;
  height: auto;
}
</style>
