// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SignatureSpidQes_reset-list_13fRA{margin:0;padding:0;list-style:none;display:block}.SignatureSpidQes_uppercase-sm_2KIp4{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.SignatureSpidQes_formContainer_um9ks{max-width:263px;margin:0 auto}.SignatureSpidQes_label_25QHg{color:#9ca6b1;margin-top:24px}.SignatureSpidQes_highlight_3dqT9{font-weight:700;font-size:20px}", ""]);
// Exports
exports.locals = {
	"reset-list": "SignatureSpidQes_reset-list_13fRA",
	"uppercase-sm": "SignatureSpidQes_uppercase-sm_2KIp4",
	"formContainer": "SignatureSpidQes_formContainer_um9ks",
	"label": "SignatureSpidQes_label_25QHg",
	"highlight": "SignatureSpidQes_highlight_3dqT9"
};
module.exports = exports;
