// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VerifaiButton_reset-list_iMuet{margin:0;padding:0;list-style:none;display:block}.VerifaiButton_uppercase-sm_ElBR-{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.VerifaiButton_icon_1T9e5{display:block;width:71px;height:auto}", ""]);
// Exports
exports.locals = {
	"reset-list": "VerifaiButton_reset-list_iMuet",
	"uppercase-sm": "VerifaiButton_uppercase-sm_ElBR-",
	"icon": "VerifaiButton_icon_1T9e5"
};
module.exports = exports;
