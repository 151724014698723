<script>
import MethodButtonBase from './MethodButtonBase';

export default {
  name: 'YesComButton',
  components: {
    MethodButtonBase,
  },
}
</script>

<template>
  <MethodButtonBase>
    <template v-slot:slot1>
      <div :class="$style.text">yes®</div>
    </template>
  </MethodButtonBase>
</template>

<style lang="scss" module>
.text {
  font-size: $font-size-lg;
}
</style>
