<script>
import removeCallingCode from './removeCallingCode';
import AppLayout from "./components/AppLayout";
import {methodActionTypes} from './config';

export default {
  name: 'App',
  components: {
    AppLayout,
  },
  props: {
    language: {
      type: String,
      default: 'en',
    },
    countryCode: {
      type: String,
      default: 'EE',
    },
    clientId: {
      type: String,
      required: true,
    },
    docId: {
      type: String,
    },
    redirectUri: {
      type: String,
      required: false,
    },
    sandbox: Boolean,
    translations: {
      type: Object,
      default: () => ({}),
    },
    enabledMethods: {
      type: [Object, String, Number],
      default: () => ({}),
      required: true,
    },
    enabledCountries: {
      type: [Array, String],
      default: () => ('all'),
    },
    selectedMethod: {
      type: String,
    },
    inputValues: {
      type: Object,
    },
    modifyRequestSettings: {
      type: Function,
    },
    formatFlashMessage: {
      type: Function,
    },
    beforeMethodSelection: {
      type: Function,
      default: () => {
      },
    },
    onSuccess: {
      type: Function,
      default: () => {
      },
    },
    onFail: {
      type: Function,
      default: () => {
      },
    },
    apiEndpoints: {
      type: Object,
      required: true,
    },
    otpEmail: {
      type: String,
    },
    otpPhone: {
      type: String,
    },
    isOtpSent: {
      type: Boolean,
      default: false,
    },
    emailToken: {
      type: String,
    },
    debug: {
      type: Boolean,
      default: false,
    },
    remoteMonitoringEnabled: {
      type: Boolean,
      default: false,
    },
    methodsConfigFilter: {
      type: Function,
    },
    modifyEndpointUrl: {
      type: Function,
    },
    oauthParamState: {
      type: String,
    },
  },
  computed: {
    countryCodeFromStore() {
      return this.$root.$options.storeGetters.countryCode();
    },
    identificationMethods() {
      return this.$root.$options.storeGetters.identificationMethods();
    },
  },
  watch: {
    countryCodeFromStore: {
      handler(newVal) {
        this.$root.$options.eidEasyClient.setCountryCode(newVal);
      },
      immediate: true,
    },
    language: {
      handler(newVal) {
        this.$i18n.locale = newVal;
        this.$root.$options.eidEasyClient.setLanguage(newVal);
      },
      immediate: true,
    },
  },
  created: function () {
    const {translations, $i18n} = this;
    this.changeCountry(this.countryCode);

    this.changeMethods(this.enabledMethods);

    this.updateEnabledCountries(this.enabledCountries);

    if (this.inputValues) {
      const formattedInputValues = {
        ...this.inputValues,
      };
      if (formattedInputValues.phone) {
        formattedInputValues.phone = removeCallingCode(formattedInputValues.phone);
      }

      this.updateInputValues(formattedInputValues);
    }

    if (translations) {
      Object.keys(translations)
          .forEach((locale) => {
            const currentMessages = $i18n.messages[locale] || {};
            $i18n.setLocaleMessage(locale, {...currentMessages, ...translations[locale]});
          });
    }

    this.updateOtpEmail(this.otpEmail);
    this.updateOtpPhone(this.otpPhone);
    this.updateIsOtpSent(this.isOtpSent);
    this.updateEmailToken(this.emailToken);

    if (this.emailToken) {
      this.updateInputValues({
        email_token: this.emailToken,
      });
    }

    if (this.debug) {
      console.log('----------- Settings ----------');
      console.log('language:', this.language);
      console.log('countryCode:', this.countryCode);
      console.log('clientId:', this.clientId);
      console.log('redirectUri:', this.redirectUri);
      console.log('sandbox:', this.sandbox);
      console.log('translations:', this.translations);
      console.log('enabledMethods:', this.enabledMethods);
      console.log('onSuccess:', this.onSuccess);
      console.log('onFail:', this.onFail);
      console.log('apiEndpoints:', this.apiEndpoints);
      console.log('oauthParamState:', this.oauthParamState);
      console.log('----------- /Settings ----------');
    }

  },
  mounted() {
    let countryToSelect = this.countryCode.toUpperCase();
    let selectedMethod;
    if (this.selectedMethod) {
      selectedMethod = this.selectedMethod;
    } else if ((this.otpPhone || this.otpEmail) && this.isOtpSent) {
      selectedMethod = methodActionTypes.OTP_EMAIL;
    }
    if (selectedMethod) {
      this.selectMethod(selectedMethod);

      // we need to make sure that when a new method is selected then we also update the selected country
      // this comes into play when the method is preselected but no country is provided or the provided
      // country is not in the method's supportedCountries
      // e.g. let's say we preselect fi-id-login, but do not provide the country (default being ee)
      // in this case the widget would make a request to the default ee. endpoint - that we do not want
      // so, we'll take the first country from the countries whitelist instead
      const {supportedCountries} = this.$root.$options.eidEasyMethodsConfig.methodsByActionType[selectedMethod];
      if (Array.isArray(supportedCountries) && !supportedCountries.includes(countryToSelect)) {
        countryToSelect = supportedCountries[0].toUpperCase();
      }
    } else if ((this.otpPhone || this.otpEmail) && this.isOtpSent) {
      this.selectMethod(methodActionTypes.OTP_EMAIL);
    }
    this.changeCountry(countryToSelect);
  },
  methods: {
    selectMethod(method) {
      return this.$root.$options.storeActions.selectMethod(method);
    },
    changeCountry(country) {
      return this.$root.$options.storeActions.changeCountry(country);
    },
    changeMethods(methods) {
      return this.$root.$options.storeActions.changeMethods(methods);
    },
    updateEnabledCountries(countries) {
      return this.$root.$options.storeActions.updateEnabledCountries(countries);
    },
    updateInputValues(values) {
      return this.$root.$options.storeActions.updateInputValues(values);
    },
    updateOtpEmail(value) {
      return this.$root.$options.storeActions.updateOtpEmail(value);
    },
    updateOtpPhone(value) {
      return this.$root.$options.storeActions.updateOtpPhone(value);
    },
    updateIsOtpSent(value) {
      return this.$root.$options.storeActions.updateIsOtpSent(value);
    },
    updateEmailToken(value) {
      return this.$root.$options.storeActions.updateEmailToken(value);
    },
  },
}
</script>

<template>
  <div :class="$style.app">
    <AppLayout />
  </div>
</template>

<style lang="scss" module>
.app {
  font-family: $font-family;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  line-height: $line-height;
  min-width: 260px;
}

.app * {
  box-sizing: border-box;
}
</style>
