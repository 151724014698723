// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IdinButton_reset-list_IyWUj{margin:0;padding:0;list-style:none;display:block}.IdinButton_uppercase-sm_3Ozql{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.IdinButton_icon_4fHBl{display:block;width:34px;height:auto;margin-right:8px}", ""]);
// Exports
exports.locals = {
	"reset-list": "IdinButton_reset-list_IyWUj",
	"uppercase-sm": "IdinButton_uppercase-sm_3Ozql",
	"icon": "IdinButton_icon_4fHBl"
};
module.exports = exports;
