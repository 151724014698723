import {
  allMethods as allClientMethods,
  methodActionTypes,
  methodTypes,
  moduleNames
} from './config';

import methodButtonNames from './components/methodButtons/methodButtonNames';
import viewNames from './views/viewNames';

const allWidgetMethods = Object.freeze({
  [methodTypes.IDENTIFICATION]: [
    {
      actionType: methodActionTypes.BE_ID_LOGIN,
      buttonName: methodButtonNames.IdCardButtonBe,
      translationKeys: {
        title: 'id-card',
      },
    },
    {
      actionType: methodActionTypes.EE_ID_LOGIN,
      buttonName: methodButtonNames.IdCardButtonEE,
      translationKeys: {
        title: 'id-card',
      },
    },
    {
      actionType: methodActionTypes.FI_ID_LOGIN,
      buttonName: methodButtonNames.IdCardButtonFi,
      translationKeys: {
        title: 'id-card',
      },
    },
    {
      actionType: methodActionTypes.LV_ID_LOGIN,
      buttonName: methodButtonNames.IdCardButtonLv,
      translationKeys: {
        title: 'id-card',
      },
    },
    {
      actionType: methodActionTypes.LT_ID_LOGIN,
      buttonName: methodButtonNames.IdCardButton,
      translationKeys: {
        title: 'id-card',
      },
    },
    {
      actionType: methodActionTypes.PT_ID_LOGIN,
      buttonName: methodButtonNames.IdCardButtonPt,
      translationKeys: {
        title: 'id-card',
      },
    },
    {
      actionType: methodActionTypes.RS_ID_LOGIN,
      buttonName: methodButtonNames.IdCardButton,
      translationKeys: {
        title: 'id-card',
      },
    },
    {
      actionType: methodActionTypes.EE_MOBILE_ID,
      buttonName: methodButtonNames.MobileIdButtonEE,
      viewName: viewNames.MobileIdAuth,
      translationKeys: {
        title: 'mobile-id',
      }
    },
    {
      actionType: methodActionTypes.LV_EPARAKSTS_MOBILE_LOGIN,
      buttonName: methodButtonNames.EparakstsMobileButton,
      translationKeys: {
        title: 'eparaksts-mobile',
      },
    },
    {
      actionType: methodActionTypes.LT_MOBILE_ID,
      buttonName: methodButtonNames.MobileIdButton,
      viewName: viewNames.MobileIdAuth,
      translationKeys: {
        title: 'mobile-id',
      }
    },
    {
      actionType: methodActionTypes.SMART_ID,
      buttonName: methodButtonNames.SmartIdButton,
      viewName: viewNames.SmartIdAuth,
      translationKeys: {
        title: 'smart-id',
      }
    },
    {
      actionType: methodActionTypes.FREJA_EID_LOGIN,
      buttonName: methodButtonNames.FrejaEidButton,
      viewName: viewNames.IdentificationFrejaId,
      translationKeys: {
        title: 'freja-eid',
      }
    },
    {
      actionType: methodActionTypes.ZEALID_LOGIN,
      buttonName: methodButtonNames.ZealIdButton,
      viewName: viewNames.IdentificationZealId,
      translationKeys: {
        title: 'zeal-id',
      },
    },
    {
      actionType: methodActionTypes.CZ_MOJEID,
      buttonName: methodButtonNames.MojeIdButton,
      translationKeys: {
        title: 'moje-id',
      },
    },
    {
      actionType: methodActionTypes.FI_FTN_LOGIN,
      buttonName: methodButtonNames.FinnishTrustNetworkButton,
      translationKeys: {
        title: 'finnish-online-bank',
      },
    },
    {
      actionType: methodActionTypes.AT_HANDY_SIGNATUR_LOGIN,
      buttonName: methodButtonNames.AtHandyButton,
      translationKeys: {
        title: 'austrian-handy-signatur',
      },
    },
    {
      actionType: methodActionTypes.ITSME_LOGIN,
      buttonName: methodButtonNames.ItsmeButton,
      translationKeys: {
        title: 'itsme',
      },
    },
    {
      actionType: methodActionTypes.SE_BANKID_LOGIN,
      buttonName: methodButtonNames.BankIdButton,
      translationKeys: {
        title: 'bankid',
      },
    },
    {
      actionType: methodActionTypes.IDIN_LOGIN,
      buttonName: methodButtonNames.IdinButton,
      translationKeys: {
        title: 'iDIN',
      },
    },
    {
      actionType: methodActionTypes.LV_EPARAKSTS_ID_LOGIN,
      buttonName: methodButtonNames.EparakstsSmartCardButton,
      translationKeys: {
        title: 'eparaksts-smart-card',
      }
    },
    {
      actionType: methodActionTypes.EDOAPP_EID_LOGIN,
      buttonName: methodButtonNames.EdoAppEidButton,
      translationKeys: {
        title: 'edoapp-eid',
      }
    },
  ],
  [methodTypes.SIGNATURE]: [
    {
      actionType: methodActionTypes.BE_IDCARD_SIGNATURE,
      buttonName: methodButtonNames.IdCardButtonBe,
      translationKeys: {
        title: 'id-card',
      },
    },
    {
      actionType: methodActionTypes.EE_IDCARD_SIGNATURE,
      buttonName: methodButtonNames.IdCardButtonEE,
      translationKeys: {
        title: 'id-card',
      },
    },
    {
      actionType: methodActionTypes.FI_IDCARD_SIGNATURE,
      buttonName: methodButtonNames.IdCardButtonFi,
      translationKeys: {
        title: 'id-card',
      },
    },
    {
      actionType: methodActionTypes.LV_IDCARD_SIGNATURE,
      buttonName: methodButtonNames.IdCardButtonLv,
      translationKeys: {
        title: 'id-card',
      },
    },
    {
      actionType: methodActionTypes.LT_IDCARD_SIGNATURE,
      buttonName: methodButtonNames.IdCardButton,
      translationKeys: {
        title: 'id-card',
      },
    },
    {
      actionType: methodActionTypes.PT_IDCARD_SIGNATURE,
      buttonName: methodButtonNames.IdCardButtonPt,
      translationKeys: {
        title: 'id-card',
      },
    },
    {
      actionType: methodActionTypes.SMARTID_SIGNATURE,
      buttonName: methodButtonNames.SmartIdButton,
      viewName: viewNames.SignatureSmartId,
      translationKeys: {
        title: 'smart-id',
      }
    },
    {
      actionType: methodActionTypes.EE_MOBILEID_SIGNATURE,
      buttonName: methodButtonNames.MobileIdButtonEE,
      viewName: viewNames.SignatureMobileId,
      translationKeys: {
        title: 'mobile-id',
      }
    },
    {
      actionType: methodActionTypes.LT_MOBILEID_SIGNATURE,
      buttonName: methodButtonNames.MobileIdButton,
      viewName: viewNames.SignatureMobileId,
      translationKeys: {
        title: 'mobile-id',
      }
    },
    {
      actionType: methodActionTypes.LV_EPARAKSTS_MOBILE_SIGNATURE,
      buttonName: methodButtonNames.EparakstsMobileButton,
      translationKeys: {
        title: 'eparaksts-mobile',
      },
    },
    {
      actionType: methodActionTypes.FI_FTN_ADVANCED_SIGNATURE,
      buttonName: methodButtonNames.FinnishTrustNetworkButton,
      viewName: viewNames.SignatureFtn,
      translationKeys: {
        title: 'finnish-online-bank',
      },
    },
    {
      actionType: methodActionTypes.FI_FTN_ADVANCED_FULL_SIGNATURE,
      buttonName: methodButtonNames.FinnishTrustNetworkAdvancedFullButton,
      viewName: viewNames.SignatureFtnAdvancedFull,
      translationKeys: {
        title: 'finnish-online-bank',
      },
    },
    {
      actionType: methodActionTypes.OTP_EMAIL,
      buttonName: methodButtonNames.OtpButton,
      viewName: viewNames.SignatureOtp,
      translationKeys: {
        title: 'email-sms',
      },
    },
    {
      actionType: methodActionTypes.AT_HANDY_SIGNATUR_SIGNATURE,
      buttonName: methodButtonNames.AtHandyButton,
      translationKeys: {
        title: 'austrian-handy-signatur',
      },
    },
    {
      actionType: methodActionTypes.ZEAL_ID_SIGNATURE,
      buttonName: methodButtonNames.ZealIdButton,
      translationKeys: {
        title: 'zeal-id',
      },
    },
    {
      actionType: methodActionTypes.CZ_MOJEID_ADVANCED_SIGNATURE,
      buttonName: methodButtonNames.MojeIdButton,
      translationKeys: {
        title: 'moje-id',
      },
    },
    {
      actionType: methodActionTypes.GOOGLE_SIGNATURE,
      buttonName: methodButtonNames.GoogleButton,
      translationKeys: {
        title: 'google',
      },
    },
    {
      actionType: methodActionTypes.EVROTRUST_SIGNATURE,
      buttonName: methodButtonNames.EvrotrustButton,
      viewName: viewNames.SignatureEvrotrust,
      translationKeys: {
        title: 'evrotrust',
      },
    },
    {
      actionType: methodActionTypes.SE_BANKID_SIGNATURE,
      buttonName: methodButtonNames.BankIdButton,
      translationKeys: {
        title: 'bankid',
      },
    },
    {
      actionType: methodActionTypes.D_TRUST_SIGN_ME_QUALIFIED_SIGNATURE,
      buttonName: methodButtonNames.DTrustSignMeButton,
      viewName: viewNames.SignatureDTrustSignMe,
      translationKeys: {
        title: 'D-Trust sign-me',
      },
    },
    {
      actionType: methodActionTypes.MIT_ID_SIMPLE_SIGNATURE,
      buttonName: methodButtonNames.MitIdButton,
      translationKeys: {
        title: 'MitID',
      },
    },
    {
      actionType: methodActionTypes.CHAVE_MOVEL_QUALIFIED_SIGNATURE,
      buttonName: methodButtonNames.ChaveMovelButton,
      viewName: viewNames.SignatureChaveMovel,
      translationKeys: {
        title: 'Chave Movel',
      },
    },
    {
      actionType: methodActionTypes.NORWEGIAN_BANKID_ADVANCED_SIGNATURE,
      buttonName: methodButtonNames.NoBankIdButton,
      viewName: viewNames.SignatureNoBankId,
      translationKeys: {
        title: 'bankid',
      },
    },
    {
      actionType: methodActionTypes.CERTEUROPE_USB_TOKEN_SIGNATURE,
      buttonName: methodButtonNames.CertEuropeUsbButton,
      translationKeys: {
        title: 'CertEurope USB',
      },
    },
    {
      actionType: methodActionTypes.CERTSIGN_USB_TOKEN_SIGNATURE,
      buttonName: methodButtonNames.CertSignUsbButton,
      translationKeys: {
        title: 'certSIGN USB',
      },
    },
    {
      actionType: methodActionTypes.IDIN_SIMPLE_SIGNATURE,
      buttonName: methodButtonNames.IdinButton,
      translationKeys: {
        title: 'iDIN',
      },
    },
    {
      actionType: methodActionTypes.VERIFAI_SIMPLE_SIGNATURE,
      buttonName: methodButtonNames.VerifaiButton,
      translationKeys: {
        title: 'Verifai',
      },
    },
    {
      actionType: methodActionTypes.SPID_QUALIFIED_SIGNATURE,
      buttonName: methodButtonNames.SpidButton,
      viewName: viewNames.SignatureSpidQes,
      translationKeys: {
        title: 'SPID',
      },
    },
    {
      actionType: methodActionTypes.FREJA_SIMPLE_SIGNATURE,
      buttonName: methodButtonNames.FrejaEidButton,
      viewName: viewNames.SignatureFrejaId,
      translationKeys: {
        title: 'freja-eid-signature',
      }
    },
    {
      actionType: methodActionTypes.AUDKENNI_QUALIFIED_SIGNATURE,
      buttonName: methodButtonNames.AudkenniButton,
      viewName: viewNames.SignatureAudkenni,
      translationKeys: {
        title: 'audkenni',
      }
    },
    {
      actionType: methodActionTypes.YES_COM_QUALIFIED_SIGNATURE,
      buttonName: methodButtonNames.YesComButton,
      buttonInstructions: 'sign-with-my-bank',
      translationKeys: {
        title: 'yes-com',
      }
    },
    {
      actionType: methodActionTypes.SIMPLY_SIGN_QES_SIGNATURE,
      buttonName: methodButtonNames.SimplySignButton,
      viewName: viewNames.SignatureSimplySign,
      translationKeys: {
        title: 'SimplySign',
      }
    },
  ],
});

const getAvailableMethods = function getAvailableMethods(clientMethods, widgetMethods) {
  const methods = [];
  widgetMethods.forEach(widgetMethod => {
    const clientMethod = clientMethods.find(clientMethod => clientMethod.actionType === widgetMethod.actionType);
    if (clientMethod) {
      methods.push({
        ...clientMethod,
        ...widgetMethod,
      });
    }
  });
  return methods;
}

const createGetMethodsByActionType = function createGetMethodsByActionType(methods) {
  const identificationMethods = methods[methodTypes.IDENTIFICATION].map((method) => {
    method.methodType = methodTypes.IDENTIFICATION;
    return method;
  });
  const signatureMethods = methods[methodTypes.SIGNATURE].map((method) => {
    method.methodType = methodTypes.SIGNATURE;
    return method;
  });
  const allMethods = [
    ...identificationMethods,
    ...signatureMethods,
  ];

  const methodsByActionType = {};

  allMethods.forEach(method => methodsByActionType[method.actionType] = method);

  return methodsByActionType;
};

// intersection of all the methods provided by the eideasy-browser-client and all the methods in this widget
const availableMethods = {
  [methodTypes.IDENTIFICATION]: getAvailableMethods(
    allClientMethods[methodTypes.IDENTIFICATION],
    allWidgetMethods[methodTypes.IDENTIFICATION]
  ),
  [methodTypes.SIGNATURE]: getAvailableMethods(
    allClientMethods[methodTypes.SIGNATURE],
    allWidgetMethods[methodTypes.SIGNATURE]
  ),
};

const createMethodsConfig = function createMethodsConfig({ methodsConfigFilter = null }) {
  let methods = availableMethods;

  if (methodsConfigFilter && typeof methodsConfigFilter === 'function') {
    methods = methodsConfigFilter(availableMethods);
  }

  const methodsByActionType = createGetMethodsByActionType(methods);

  return Object.freeze({
    availableMethods: methods,
    methodsByActionType,
    methodActionTypes,
    methodTypes,
    moduleNames,
  });
};

export default createMethodsConfig;
