// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SpidButton_reset-list_33H93{margin:0;padding:0;list-style:none;display:block}.SpidButton_uppercase-sm_3amFZ{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.SpidButton_icon_3ISrr{display:block;width:63px;height:auto}", ""]);
// Exports
exports.locals = {
	"reset-list": "SpidButton_reset-list_33H93",
	"uppercase-sm": "SpidButton_uppercase-sm_3amFZ",
	"icon": "SpidButton_icon_3ISrr"
};
module.exports = exports;
